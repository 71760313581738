<!--
 * @Description: 运维管理 设备管理 巡检车 inspectVehicle
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-28 10:34:49
 * @LastEditors: zhoucheng
-->
<template>
  <div class="etcDev-mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="巡检车编号">
              <el-input v-model="searchForm.deviceId"
                        placeholder="请输入巡检车编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="巡检车名称">
              <el-input v-model="searchForm.deviceName"
                        placeholder="请输入巡检车名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="巡检车类型">
              <el-select v-model="searchForm.inspectionCarType"
                         placeholder="全部">
                <el-option v-for="item in carTypeList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="厂商">
              <el-input v-model="searchForm.vendor"
                        placeholder="请输厂商"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="设备状态">
              <el-select v-model="searchForm.select"
                         placeholder="全部">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>巡检车</span>
        <el-row class="tableTitleButton">
          <!-- <el-button type="success">新 增</el-button> -->
          <el-button type="text"
                     style="margin-left:17px">批量导入</el-button>
          <el-button type="info"
                     style="margin-left:17px">导出</el-button>
          <el-button type="danger"
                     style="margin-left:17px">批量删除</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <!--  <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template> -->
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '巡检车编号',
        },
        {
          prop: 'deviceName',
          label: '巡检车名称',
        },
        {
          prop: 'inspectionCarTypeDesc',
          label: '巡检车类型',
        },
        {
          prop: 'videoId',
          label: '绑定摄像头编号',
        },
        {
          prop: 'vendor',
          label: '厂商',
        },
        {
          prop: 'type2',
          label: '设备状态',
        },
      ],
      tableList: {
        list: [
        ]
      },
      searchForm: {},
      // 巡检车类型
      carTypeList: []


    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getCarTypeList()
    this.getCarList()
  },
  //方法集合
  methods: {

    // 巡检车类型
    getCarTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '74685059E32747E3A2E7AA16C9D35FC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.carTypeList = response.resultEntity
      })
    },

    //初始化页面
    getCarList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$inspectVehicle.queryInspectionCarList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list

        this.tableList.list.forEach(element => {
          console.log(element, '111111111111');
          element['videoId'] = element.videoBeans[0].videoId
          // console.log(this.tableList.list, '2222222');

        });
        this.paginationConfig.total = res.resultEntity.total

      })
    },
    //查询
    onSubmit () {
      this.listMap.pageNum = 1
      this.pageNum = 1;
      this.getCarList();
    },
    //重置
    resetForm () {
      this.searchForm = {}
      // 设置pageNum为1
      this.pageNum = 1
      // 设置序号为1
      this.listMap.pageNum = 1
      this.getCarList();
    },
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getCarList();
    },

  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 180px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.etcDev-mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 90px;
      margin-top: 16px;
    }
  }
  .el-button--text {
    background: #019fe4;
    height: 32px;
    width: 94px;
    color: white;
    border-radius: 0;
    font-size: 14px;
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
